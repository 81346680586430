<!--
  后台发布成果
    + 选择一个关联机构，此机构管理员来代发
 -->
<template>
  <div class="detail">
    <detail-header title="发布成果" />
    <div class="detail_main page_width">
      <div class="main_content" v-loading="loading">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          size="small"
          label-width="180px"
          label-position="left">
          <el-form-item
            label="成果名称"
            prop="title">
            <el-input
              maxlength="50" show-word-limit
              style="width: 100%;"
              v-model="form.title"
              placeholder="请输入成果名称">
            </el-input>
          </el-form-item>
          <el-form-item
            label="成果类型"
            prop="achievementType">
            <el-select
              style="width: 100%;"
              v-model="form.achievementType"
              placeholder="请选择成果类型">
              <el-option
                v-for="item in staticData.cglx"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="成果第一完成人"
            prop="firstCompletedBy">
            <el-input
              maxlength="50" show-word-limit
              style="width: 100%;"
              v-model="form.firstCompletedBy"
              placeholder="请输入成果第一完成人">
            </el-input>
          </el-form-item>
          <el-form-item
            label="成果持有单位"
            prop="holdUnit">
            <el-input
              maxlength="50" show-word-limit
              style="width: 100%;"
              v-model="form.holdUnit"
              placeholder="请输入成果持有单位名称">
            </el-input>
          </el-form-item>
          <el-form-item
            label="所在区域"
            prop="region">
            <el-input
              maxlength="50" show-word-limit
              v-model="form.region"
              placeholder="请输入所在区域">
            </el-input>
          </el-form-item>
          <el-form-item
            label="应用行业"
            prop="industries">
            <el-select
              style="width:100%;"
              v-model="form.industries"
              multiple
              placeholder="请选择应用行业">
              <el-option
                v-for="item in industrySectorList"
                :key="item.id"
                :value="item.id"
                :label="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="技术领域"
            prop="technical">
            <el-select
              style="width:100%;"
              v-model="form.technical"
              multiple
              placeholder="请选择技术领域">
              <el-option
                v-for="item in technicalList"
                :key="item.id"
                :value="item.id"
                :label="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="成熟度"
            prop="maturity">
            <el-select
              style="width: 100%;"
              v-model="form.maturity"
              placeholder="请选择成熟度">
              <el-option
                v-for="item in staticData.csd"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="成果描述"
            prop="description">
            <el-input
              style="width: 100%;"
              type="textarea" maxlength="300" show-word-limit
              :rows="4"
              placeholder="请输入成果描述"
              v-model="form.description">
            </el-input>
          </el-form-item>
          <el-form-item
            label="合作方式"
            prop="cooperationMode">
            <el-select
              style="width:100%;"
              v-model="form.cooperationMode"
              multiple
              placeholder="请选择合作方式">
              <el-option
                v-for="item in staticData.hzfs"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="交易价格"
            prop="price">
            <el-select
              style="width:100%"
              placeholder="请选择价格类型"
              size="small"
              v-model="priceType">
              <el-option
                v-for="optionItem in priceOptions"
                :key="optionItem"
                :label="optionItem"
                :value="optionItem">
              </el-option>
            </el-select>
            <el-input
              maxlength="50" show-word-limit
              v-if="priceType === '具体值'"
              style="margin-top: 20px;width:100%"
              v-model="form.price"
              placeholder="请输入具体价格">
            </el-input>
          </el-form-item>
          <el-form-item
            label="成果资料"
            prop="attachments">
            <upload-files
              accept=""
              :showTip="false"
              :fileList.sync="form.attachments">
            </upload-files>
          </el-form-item>
          <el-form-item
            label="联系人"
            prop="contactName">
            <el-input
              maxlength="50" show-word-limit
              style="width: 100%;"
              v-model="form.contactName"
              placeholder="请输入联系人">
            </el-input>
          </el-form-item>
          <el-form-item
            label="联系电话"
            prop="contactNumber">
            <el-input
              maxlength="11" show-word-limit
              style="width: 100%;"
              v-model="form.contactNumber"
              placeholder="请输入联系电话">
            </el-input>
          </el-form-item>
          <el-form-item
            label="联系邮箱"
            prop="contactEmail">
            <el-input
              maxlength="50" show-word-limit
              style="width: 100%;"
              v-model="form.contactEmail"
              placeholder="请输入联系邮箱">
            </el-input>
          </el-form-item>
          <el-form-item
            label="是否为成果代理人"
            prop="proxyAgent">
            <el-radio-group v-model="form.proxyAgent">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <template v-if="form.proxyAgent === 1">
            <el-form-item
              label="代理人单位"
              prop="proxyUnit">
              <el-input
                maxlength="50" show-word-limit
                style="width: 100%;"
                v-model="form.proxyUnit"
                placeholder="请输入代理人单位">
              </el-input>
            </el-form-item>
            <el-form-item
              label="上传代理协议"
              prop="agentAgreement">
              <upload-files
                accept=""
                :showTip="false"
                :fileList.sync="form.agentAgreement">
              </upload-files>
            </el-form-item>
          </template>
          <template v-if="form.proxyAgent === 0">
            <el-form-item
              label="是否委托平台服务商推广"
              prop="isEntrust">
              <el-radio-group v-model="form.isEntrust">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </template>
          <el-form-item
            label="关联服务机构"
            prop="proxyOrgId">
            <el-select
              style="width: 380px;"
              v-model="form.proxyOrgId"
              filterable
              remote
              placeholder="请输入机构名称搜索"
              :remote-method="searchOrg"
              :loading="orgSelectLoading">
              <el-option
                v-for="item in orgOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button
              size="small"
              type="primary"
              @click="submit"
              :loading="btnLoading">
              确定
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
export default {
  data () {
    return {
      loading: false,
      btnLoading: false,
      // 机构下拉框loading
      orgSelectLoading: false,
      // 价格类型
      priceType: '',
      // 价格类型选择器
      priceOptions: [
        '面议',
        '具体值'
      ],
      form: {
        title: '', // 成果名称
        achievementType: '', // 成果类型
        firstCompletedBy: '', // 成果第一完成人
        holdUnit: '', // 成果持有单位
        region: '', // 所在区域
        industries: [], // 应用行业
        technical: [], // 技术领域
        maturity: '', // 成熟度
        description: '', // 成果描述
        cooperationMode: [], // 合作方式
        price: '', // 交易价格
        attachments: [], // 成果资料
        contactName: '', // 联系人
        contactNumber: '', // 联系电话
        contactEmail: '', // 联系邮箱
        proxyAgent: 0, // 是否为成果代理人
        proxyUnit: '', // 代理人单位
        proxyOrgId: '', // 关联机构 -> 会关联到机构的管理员
        agentAgreement: [], // 上传代理协议
        isEntrust: 0 // 是否委托平台服务商推广
      },
      /* form: {
          "title": "测试后台成果",
          "achievementType": "发明专利",
          "firstCompletedBy": "赵思02",
          "holdUnit": "优聘科技",
          "region": "湖北",
          "industries": [
              "1d94a23441dbdd1543558e6d62a509a9",
              "30813b3570c49000b5f2319893f0f01d",
              "57fa60e561f6e5bbdb6cfc5e30f88e4a"
          ],
          "technical": [
              "5e79b1151fb70242bd5d3f85b3ef1f90",
              "6254a2cf3348ff21ad6c86b6d67471db",
              "853f8b238a01b01811a3cb0871290284"
          ],
          "maturity": "方案级",
          "description": "非常好",
          "cooperationMode": [
              "技术服务"
          ],
          "price": "面议",
          "attachments": [
              {
                  "uid": 1628739478958,
                  "name": "Untitled-1.txt",
                  "id": "9f71153ffc16f2f352b11fa9e0551204",
                  "createdAt": "2021-08-12 11:38:00",
                  "updatedAt": "2021-08-12 11:38:00",
                  "deletedAt": null,
                  "driver": "local",
                  "originName": "Untitled-1.txt",
                  "extName": "txt",
                  "contentType": "text/plain",
                  "size": "49",
                  "path": "2021/8/12/9f71153ffc16f2f352b11fa9e0551204.txt",
                  "url": "http://192.168.1.175:9919/media/9f71153ffc16f2f352b11fa9e0551204",
                  "downloadUrl": "http://192.168.1.175:9919/media/9f71153ffc16f2f352b11fa9e0551204/download",
                  "status": "success"
              }
          ],
          "contactName": "赵思02",
          "contactNumber": "17371278540",
          "contactEmail": "1159902844",
          "proxyAgent": 1,
          "proxyUnit": "优聘",
          "proxyOrgId": "3540dc6529823f4e38d41b6526635a64",
          "agentAgreement": [
              {
                  "uid": 1628739496044,
                  "name": "tst.txt",
                  "id": "f37212e2f906fd0167197e70dc0dcf8d",
                  "createdAt": "2021-08-12 11:38:16",
                  "updatedAt": "2021-08-12 11:38:16",
                  "deletedAt": null,
                  "driver": "local",
                  "originName": "tst.txt",
                  "extName": "txt",
                  "contentType": "text/plain",
                  "size": "1",
                  "path": "2021/8/12/f37212e2f906fd0167197e70dc0dcf8d.txt",
                  "url": "http://192.168.1.175:9919/media/f37212e2f906fd0167197e70dc0dcf8d",
                  "downloadUrl": "http://192.168.1.175:9919/media/f37212e2f906fd0167197e70dc0dcf8d/download",
                  "status": "success"
              }
          ],
          "isEntrust": 0
      }, */
      rules: {
        title: [
          { required: true, message: '请输入成果名称', trigger: 'blur' }
        ],
        achievementType: [
          { required: true, message: '请选择成果类型', trigger: 'change' }
        ],
        firstCompletedBy: [
          { required: true, message: '请输入成果第一完成人', trigger: 'blur' }
        ],
        holdUnit: [
          { required: true, message: '请输入成果持有单位名称', trigger: 'blur' }
        ],
        region: [
          { required: true, message: '请输入所在区域', trigger: 'blur' }
        ],
        industries: [
          { required: true, message: '请选择应用行业', trigger: 'change' }
        ],
        technical: [
          { required: true, message: '请选择技术领域', trigger: 'change' }
        ],
        maturity: [
          { required: true, message: '请输入成熟度', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入简介', trigger: 'blur' }
        ],
        cooperationMode: [
          { required: true, message: '请选择合作方式', trigger: 'change' }
        ],
        price: [
          { required: true, message: '请选择或输入价格', trigger: 'blur' }
        ],
        attachments: [
          { required: true, message: '请上传成果资料', trigger: 'change' }
        ],
        contactName: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        contactEmail: [
          { required: true, message: '请输入联系邮箱', trigger: 'blur' }
          // { pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, message: '请输入正确邮箱格式', trigger: 'blur' }
        ],
        contactNumber: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ],
        // proxyUnit: [
        //   { required: true, message: '请输入成果代理人单位', trigger: 'change' }
        // ],
        proxyOrgId: [
          { required: true, message: '请选择关联机构', trigger: 'blur' }
        ]
      },
      // 机构列表选项
      orgOptions: []
    }
  },
  computed: {
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    },
    // 行业领域下拉列表
    industrySectorList () {
      return this.$store.state.industrySectorList
    }
  },
  watch: {
    priceType (value) {
      if (value === '面议') {
        this.form.price = '面议'
      } else if (value === '具体值') {
        this.form.price = ''
      } else {
        this.form.price = ''
      }
    }
  },
  methods: {
    // 搜索机构
    searchOrg (value) {
      if (value !== '') {
        this.getKeywordsDownOrgList(value)
      } else {
        this.orgOptions = []
      }
    },
    // 提交表单
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.createAchievement()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 根据关键词来搜索结构
    getKeywordsDownOrgList (value) {
      this.orgSelectLoading = true
      api.getKeywordsDownOrgList({
        keywords: value
      }).then(res => {
        if (res.data.code === 0) {
          console.log('机构列表', res.data.data)
          this.orgOptions = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.orgSelectLoading = false
      })
    },
    // 新增成果
    createAchievement () {
      this.btnLoading = true
      api.createAchievement({
        ...this.form
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('添加成功！')
          this.$router.replace('/resource-require/achievement')
        } else {
          this.$message.error(res.data.message)
        }
        this.btnLoading = false
      }).catch(err => {
        this.btnLoading = false
        console.log(err)
        this.$message.error('请求出错')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 560px;
      margin: 0 auto;

      .btn_box {
        padding: 30px 0 0 180px;
      }
    }
  }
}
</style>
